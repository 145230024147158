import { gql } from "@apollo/client";

const CREATE_INVITED_USER = gql`
  mutation CreateInvitedUser(
    $firstName: String!
    $lastName: String!
    $email: String
    $rut: String
    $phone: String
  ) {
    createInvitedUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      rut: $rut
      phone: $phone
    ) {
      _id
      firstName
      lastName
      email
      rut
      phone
    }
  }
`;

export default CREATE_INVITED_USER;
