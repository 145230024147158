import { gql } from "@apollo/client";

const GET_USER_BENEFIT = gql`
  query GetUserBenefit($id: ID!) {
    getUserBenefit(_id: $id) {
      _id
      activePlan {
        _id
        serviceTier {
          name
          basePrice
        }
        name
        billingType
        baseDiscount
      }
      periodStart
      periodEnd
      discounts {
        event
        subscription
      }
      integrations {
        mok {
          registered
          status
        }
      }
      freeEventSlots
      status
    }
  }
`;

export default GET_USER_BENEFIT;
