import { gql } from "@apollo/client";

const GET_INVITED_USERS = gql`
  query GetInvitedUsers($inviter: ID, $limit: Int) {
    getInvitedUsers(inviter: $inviter, limit: $limit) {
      total
      instances {
        _id
        firstName
        lastName
        phone
        rut
        email
      }
    }
  }
`;

export default GET_INVITED_USERS;
