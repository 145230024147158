import { gql } from "@apollo/client";

const GET_INTERESTS = gql`
  query GetInterestsConfigs($limit: Int) {
    getInterestsConfigs(limit: $limit) {
      total
      instances {
        _id
        category
        name
        icon
      }
    }
    getPopularEventInterests
  }
`;

export default GET_INTERESTS;
