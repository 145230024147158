"use client";

import { useLazyQuery } from "@apollo/client";
import { GET_INTERESTS } from "queries/community";
import { useEffect, useMemo } from "react";
import obj from "utils/object";

function useInterests() {
  const [getInterests, { data, loading }] = useLazyQuery(GET_INTERESTS, {
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    getInterests({ variables: { limit: 100 } });
  }, [getInterests]);

  return useMemo(() => {
    if (!data?.getInterestsConfigs?.instances || loading)
      return [[], [], {}, []];

    const categories = {},
      interests = [],
      dictionary = {},
      popularInterests = [
        "6446e2631b98b151af7f48fe", // aire libre
        "6446e2631b98b151af7f48df", // cafe social
        "6446e2631b98b151af7f48fd", // paseos recreativos
        "6446e2631b98b151af7f48e5", // caminata deportiva
      ];

    data.getInterestsConfigs.instances.forEach((x) => {
      if (!categories[x.category]) categories[x.category] = [];
      categories[x.category].push(x._id);
      interests.push(x);
      dictionary[x._id] = x.name;
    });

    data.getPopularEventInterests.forEach((x) => {
      popularInterests.push(x);
    });

    return {
      categories,
      interests: interests.sort((a, b) => obj.sortBy(a, b, "name")),
      dictionary,
      popularInterests,
    };
  }, [data, loading]);
}

export default useInterests;
