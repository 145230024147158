import "styles/globals.scss";

import { AuthProvider } from "contexts/auth/authProvider";
import { NetworkProvider } from "session/networkProvider";
import { MuiTheme /*, roboto*/ } from "session/themeProvider";
import { fetchConfig } from "server/fetchConfig";
import { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import initWorkbox from "server/workbox";
import Hotjar from "@hotjar/browser";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/es";

export default function RootLayout({ Component, pageProps }) {
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    initWorkbox().register();
    Hotjar.init(process.env.HOTJAR_SITE_ID, process.env.HOTJAR_VERSION);
    fetchConfig().then(({ config, loading }) => {
      setConfig(config);
      setLoading(loading);
    });
  }, []);

  useEffect(() => {
    if (
      process.env.NODE_ENV === "production" &&
      process.env.GOOGLE_TAG_MANAGER_ID
    ) {
      TagManager.initialize({
        auth: process.env.GOOGLE_TAG_MANAGER_AUTH,
        gtmId: process.env.GOOGLE_TAG_MANAGER_ID,
        preview: process.env.GOOGLE_TAG_MANAGER_PREVIEW,
        events: {
          sendUserInfo: "userInfo",
        },
      });
    }
  }, []);

  return (
    <MuiTheme>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <NetworkProvider {...{ config, loading }}>
          <AuthProvider>
            <Component {...pageProps} />
          </AuthProvider>
        </NetworkProvider>
      </LocalizationProvider>
    </MuiTheme>
  );
}
