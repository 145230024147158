import { gql } from "@apollo/client";

const APPEND_METRIC = gql`
  mutation AppendMetric($domain: String!, $action: String!, $userId: Boolean!) {
    appendMetric(domain: $domain, action: $action, userId: $userId) {
      userId
    }
  }
`;

export default APPEND_METRIC;
