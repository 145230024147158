import { gql } from "@apollo/client";

const POST_BASIC_SIGNUP = gql`
  mutation BasicSignup(
    $email: String!
    $password: String!
    $interests: [ID!]
    $signUpCode: String
    $referralCode: String
    $integrationPayload: SignUpIntegrationPayload
    $info: BasicSignupProfileInput!
    $source: String
  ) {
    basicSignup(
      email: $email
      password: $password
      interests: $interests
      signUpCode: $signUpCode
      referralCode: $referralCode
      integrationPayload: $integrationPayload
      info: $info
      source: $source
    ) {
      tokens {
        accessToken
      }
    }
  }
`;

export default POST_BASIC_SIGNUP;
